.checkmark{margin-left:auto;margin-right:auto;width:80px;height:80px;border-radius:50%;display:block;stroke-width:4;stroke:#fff;stroke-miterlimit:10;box-shadow:inset 0px 0px 0px #7ac142;animation:fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}.checkmark__circle{stroke-dasharray:250;stroke-dashoffset:166;stroke-width:2;stroke-miterlimit:10;stroke:#7ac142;fill:none;animation:stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}.checkmark__check{transform-origin:50% 50%;stroke-dasharray:48;stroke-dashoffset:48;animation:stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke{100%{stroke-dashoffset:0}}@keyframes scale{0%,100%{transform:none}50%{transform:scale3d(1.1, 1.1, 1)}}@keyframes fill{100%{box-shadow:inset 0px 0px 0px 40px #7ac142}}.notes h2{margin-top:20px;text-align:center;margin-bottom:0px}.notes .reference{text-align:center}.notes .reference>div{display:inline-block;box-shadow:0px 10px 10px rgba(0,0,0,0.1);padding:5px;margin-bottom:5px;font-size:16px}.notes .reference>div>span{text-transform:uppercase;color:var(--primary-color);font-weight:bold}.notes p{text-align:center}

.process-steps.timeline{justify-content:center}.process-steps.timeline li.ui-tabs-done span{border-color:#7ac142 !important;background-color:#7ac142 !important;color:#FFF}.process-steps.timeline li.ui-tabs-done h5{color:#7ac142;font-weight:600}.process-steps.timeline li.ui-tabs-cancelled span{border-color:#740D0A !important;background-color:#740D0A !important;color:#FFF}.process-steps.timeline li.ui-tabs-cancelled h5{color:#740D0A;font-weight:600}.process-steps.timeline li.ui-tabs-active span{border-color:orange !important;background-color:orange !important;color:#FFF}.process-steps.timeline li.ui-tabs-active h5{color:orange;font-weight:600}



/*
.address .table td.header {
    padding-right:15px;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
    padding:0px;
}
*/



.delivery .pickup, .delivery .shipping {
    margin-top:10px;
}
.delivery .pickup .address, .delivery .shipping .address {
    margin-top:5px;
}
.delivery-recap .shipping-status {
    display:flex;
    flex-wrap: wrap;
}
.delivery-recap .shipping-status .package {
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    padding: 0px 10px;
    margin-bottom: 1rem;
    margin-left:20px;
}
.delivery-recap .shipping-status .package h5 {
    font-family: inherit;
    margin-bottom:5px
}
.shippingHistoryCollapse > a i:last-child {
    margin-left:5px;
    transition: 0.3s ease all;
}
.shippingHistoryCollapse > a.opened i:last-child {
    transform: rotate(180deg);
}




/*
.address .table td.header {
    padding-right:15px;
    font-weight: bold;
}

.address .table td {
    border-top:0px;
    white-space: nowrap;
}
*/



.promo-codes {
    margin-bottom: 20px;
}
.promo-codes span.code {
   font-weight: 600;
   color: #28a745
}
.promo-codes span.note {
    margin-left:10px;
}


.page-layout .order-retrieve{margin-top:0px}.order-retrieve{position:relative;background-image:linear-gradient(-180deg, rgba(0,0,0,0.3), rgba(0,0,0,0));min-height:450px;padding-top:50px;padding-bottom:50px;overflow:hidden;height:100%;margin-top:50px}.order-retrieve>img{position:absolute;max-width:none;min-width:100%;top:0px;z-index:-1}.order-retrieve .landing-wide-form{background-color:rgba(255,255,255,0.9);border-bottom:1px solid rgba(0,0,0,0.075);box-shadow:0 10px 10px rgba(0,0,0,0.1);position:relative;padding:15px}.order-retrieve .form-control{height:calc(1.5em + 1rem + 2px);padding:.5rem 1rem;font-size:1.25rem;line-height:1.5;border-radius:.3rem}.order-retrieve .heading-block h1{font-size:50px;font-weight:900;color:#ffffff;text-shadow:0 2px 5px rgba(0,0,0,0.5)}.order-retrieve .subtitle{display:block;text-align:center;margin-bottom:5px;font-size:20px}.order-retrieve button.order{width:100%}@media (max-width: 991.98px){.order-retrieve button.order{margin-top:10px !important}}

